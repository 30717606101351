import { MOBILE_APP_STORE_URL } from '@Constants/commons'
import { PRIVATE_ROUTES } from '@Constants/routes'
import { useSideBarPage } from '@Hooks/SideBar/useSideBarPage'
import { AppBar, Box, Button, IconButton, Menu, MenuList, Stack, Toolbar, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import KDDownloadConversation from '@Pages/Common/KDDownloadConversation'
import { useConversationSession } from '@Store/conversationSession'
import { useUser } from '@Store/user'
import { IconDots, IconMenuDeep, IconPlus, IconX } from '@tabler/icons-react'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface MobileAppBarProps {
	onMenuClick: () => void
}

const MobileAppBar: React.FC<MobileAppBarProps> = ({ onMenuClick }) => {
	const { title, elevation, anchorEl, handleMenuOpen, handleMenuClose } = useSideBarPage()
	// For mobile view header more action
	const { conversationId, disablePromptInputBar } = useConversationSession()
	const theme = useTheme()
	const { mobileAppInfo, toggleMobileAppInfo } = useUser()
	const navigate = useNavigate()
	const location = useLocation()
	const isMobileActualDevice = /Mobi|Android/i.test(navigator.userAgent)

	const handleOpenMobileApp = () => {
		if (/iPhone/i.test(navigator.userAgent)) {
			window.location.href = MOBILE_APP_STORE_URL.IOS
			return
		} 
		if (/Android/i.test(navigator.userAgent)) {
			window.location.href = MOBILE_APP_STORE_URL.ANDROID
		}
	}

	return (
		<AppBar
			position="fixed"
			elevation={elevation}
			color="transparent"
			data-cy="mobile-appbar"
			sx={{
				backgroundColor: 'background.default',
				transition: theme.transitions.create(['background-color', 'box-shadow'], {
					duration: theme.transitions.duration.short,
				}),
			}}
		>
			<Box>
				{isMobileActualDevice && mobileAppInfo && (
					<Toolbar sx={{ borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'primary.main' }}>
						<IconButton onClick={toggleMobileAppInfo}>
							<IconX size={20} />
						</IconButton>
						<Stack px={1} spacing={1} direction={'row'} alignItems={'center'} flex={1}>
							<img style={{ borderRadius: 10 }} alt="ADB_logo" src="/icons/mobile_app_logo.png" width={44} />
							<Box>
								<Typography variant={'h5Bold'}>{'ADB Navigator'}</Typography>
								<Typography color={'grey.500'} sx={{ fontSize: 14 }}>{`${/Android/i.test(navigator.userAgent) ? 'The' : ''} Asian Development Bank`}</Typography>
							</Box>
						</Stack>
						<Button
							sx={{ borderRadius: 10 }}
							color={'default'}
							variant={'outlined'}
							onClick={handleOpenMobileApp}
						>
							{'Open'}
						</Button>
					</Toolbar>
				)}
				<Toolbar>
					<IconButton edge="start" color="inherit" aria-label="menu" onClick={onMenuClick} data-cy="mobile-menu-toggle">
						<IconMenuDeep />
					</IconButton>
					<Typography variant="h6Bold" sx={{ flexGrow: 1, textAlign: 'center' }}>
						{title}
					</Typography>
					{/* Render this components only for kd response details page only */}
					{location.pathname.startsWith(PRIVATE_ROUTES.conversationId.replace('/:id?', '')) && (
						<Stack direction={'row'} spacing={1}>
							<Button
								disabled={disablePromptInputBar}
								color={'primary'}
								variant={'contained'}
								onClick={() => navigate(PRIVATE_ROUTES.knowledge)}
								startIcon={<IconPlus />}
							>
							</Button>
							<IconButton
								disabled={disablePromptInputBar}
								onClick={handleMenuOpen}
							>
								<IconDots size={20} />
							</IconButton>
						</Stack>
					)}
				</Toolbar>
			</Box>
			{/* Render this components only for kd response details page only */}
			{location.pathname.startsWith(PRIVATE_ROUTES.conversationId.replace('/:id?', '')) && (
				<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
					<MenuList sx={{ paddingY: 0 }}>
						<KDDownloadConversation
							id={conversationId}
							handleMenuClose={handleMenuClose}
						/>
					</MenuList>
				</Menu>
			)}
		</AppBar>
	)
}

export default MobileAppBar
