import { QUERY_KEY } from "@Constants/commons"
import { getMaintenanceMode } from "@Requests/maintenance.api"
import { useQuery } from "@tanstack/react-query"

export const useGetMaintenanceMode = () => {
	const { data, isLoading } = useQuery({
		queryKey: [QUERY_KEY.GET_MAINTENANCE_MODE],
		queryFn: getMaintenanceMode,
		refetchOnWindowFocus: true,
	})
	return {
		data,
		isLoading,
	}
}