import { useGetMaintenanceMode } from "@Data-Hooks/Queries/useApp";
import { useEffect, useState } from "react";

export const useApp = () => {
	const [isMaintenanceMode, setIsMaintenanceMode] = useState(false)
	const { data, isLoading } = useGetMaintenanceMode()
	useEffect(() => {
		if(data) {
			setIsMaintenanceMode(data.maintenanceMode === 'true')
		}
	}, [data])

	return {
		isMaintenanceMode,
		isLoading,
	}
}