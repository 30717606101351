import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export const useConversationSession = create<IConversationSessionStore>()(
	persist(
		(set) => ({
			disablePromptInputBar: false,
			conversationId: '',
			updateDisablePromptInputBar: (disablePromptInputBar: boolean) => set({ disablePromptInputBar }),
			updateConversationId: (conversationId: string) => set({ conversationId }),
		}),
		{ name: 'conversationSessionStore', storage: createJSONStorage(() => sessionStorage) }
	)
)